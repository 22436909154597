import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

// const URL_API = 'http://localhost:8000'
const URL_API = 'https://api.plus.datari.net'
// 
Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    status: null,
    token: localStorage.getItem('token') || '',
    user: {}
  },

  mutations:{
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
  },
  actions:{
    login({commit},user){
      return new Promise((resolve,reject) => {
        commit('auth_request')
        axios({
          url: URL_API+'/auth/login/',
          data: user,
          method: 'POST'
        })
        .then(response => {
          const token = response.data.access_token;
          const user = response.data.user;
          axios.defaults.headers.common['Authorization'] = 'Bearer '+token
          localStorage.setItem('token',token)
          localStorage.setItem('username',user.username)
          localStorage.setItem('user_id',user.pk)          
          commit('auth_success',token,user)
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          delete axios.defaults.headers.common['Authorization']
          reject(err)
        })
      })
    },

    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        // axios({
        //   url: URL_API+'/auth/logout/',
        //   method:'POST'
        // })

        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('user_id')       
        resolve()
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    get_user: state => state.user
  }
})
