<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    menulateral:true
  }),
  created(){
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(logout)
        }
        throw err;
      });
    });
    
    // document.documentElement.style.overflow = 'hidden';
  }
};
</script>
