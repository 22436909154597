import Vue from 'vue'
import store from '../store/index.js'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import IndexView from '../views/indexView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/index',
    name: 'Index',
    component: IndexView
  },
  {
    path: '/',
    component: Dashboard,
    props: true,
    children: [
      {
        path: '',
        component: () => import('../views/Home.vue'),
        props: true,
      },
      {
        path: 'projects/new_project/',
        component: () => import(/* webpackChunkName: "about" */ '../views/NewProject.vue'),
      },
      { 
        name:'projectOptions',
        path: 'projects/new_project/select_graphs/',
        props:true,
        component: () => import('../views/selectProjectOptions.vue')
      },
      {
        path: 'projects/:numero_proyecto/:url_dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/selectView.vue'),
        props:true,
        name: 'FileView'
      }
    ],
    meta: {
      requiresAuth: true
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})

export default router
