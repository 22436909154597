<template>
  <v-app>
    <v-app-bar  
      app dark elevation='0'  clipped-left
      color="blue darken-4" 
    >
      <v-app-bar-nav-icon @click.stop="menulateral = !menulateral">
      </v-app-bar-nav-icon>
        <v-toolbar-title>
          <div class="d-flex align-center">
            <v-img
              alt="labict logo"
              class="shrink "
              contain
              src="@/media/logo_blanco.png"
              transition="scale-transition"
              width="120"
              height ="30"
            />
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account</v-icon>
        <span class="ml-1 mr-2">{{user}}</span>
        <v-btn
          @click="logout"
          v-if="isLoggedIn"
          text
        > 
          <v-icon>mdi-power-cycle</v-icon>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer 
        v-model="menulateral" 
        floating
        app
        :permanent="menulateral"
        clipped
        width="295"
        dark
        color="blue darken-3"
      >
      <!-- {{user_projects}} -->
      <v-list nav 
        class=" mt-1 d-flex flex-column"
        height="95%"

      >
        <v-list-item  
          class="px-0"   
          link :to="{ path:'/'}" 
          active-class="white--text blue darken-3"
        >
          <v-list-item-avatar>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content >
              <h4 class="font-weight-medium">Home</h4>
          </v-list-item-content>
        </v-list-item>

         <v-list-item  class="px-0"  
            link :to="{ path: '/projects/new_project/'}" 
            active-class="white--text blue darken-3"
          >
          <v-list-item-avatar>
              <v-icon >mdi-file-plus-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content >
            <h4 class="font-weight-medium">Crear Proyecto</h4>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
        <div id="lista_proyectos">
          <div class="text-center">
            <v-progress-circular
              v-if="loading"
              :size="30"
              color="dark"
              indeterminate
              class="mt-4"
            ></v-progress-circular>
          </div>
          <template v-for="proyecto in proyectos"  >
            <v-list-group 
              prepend-icon="mdi-file-chart-outline" 
              active-class="white--text blue darken-2"
            >
              <template v-slot:activator >
                <v-list-item-title >
                   <h4 class="font-weight-medium">{{proyecto.nombre}}</h4>
                </v-list-item-title>
              </template>
              <v-list-item class="px-0" 
                v-for="dashboard in proyecto.file_json.dashboards" :key="dashboard.url"
                link :to="{name: 'FileView', params: {'data':proyecto.file_json,'numero_proyecto':proyecto.file_json.numero_proyecto, 'url_dashboard': dashboard.url}}"
                active-class="white--text blue darken-3"
              >
           
                <v-list-item-avatar>
                  <v-icon>mdi-chart-box</v-icon>
                </v-list-item-avatar>
                <v-list-item-content >
                    <h4 class="font-weight-medium">{{dashboard.nombre}}</h4>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </div>
      
      </v-list>
    </v-navigation-drawer>
     <v-main style="background-color:#D5D8DC" >
        <v-container fluid transition="fade-transition">
          <router-view 
            :cantidad_proyectos="proyectos.length" 
            :proyectos="proyectos"
            :new_proyectos="new_proyectos" 
            :user_id="user_id" 
            @update="updateProjects"
            :loading="loading"
          >
          </router-view>
        </v-container>
    </v-main>
    <v-footer dark app padless>
      <span class="white--text ml-2"><small>LAB ICT &copy; {{ new Date().getFullYear() }}</small> </span>
    </v-footer>
  </v-app>
</template>

<style scoped>
.v-list-item {
  flex: 0;
}

</style>
<script>

// const API_URL = 'http://localhost:8000/'

const API_URL = 'https://api.plus.datari.net/'

import axios from 'axios';

export default {
  name: 'Dashboard',
  data: () => ({
    menulateral:true,
    loading:false,
    user:localStorage.getItem('username'),
    user_id:localStorage.getItem('user_id'),
    proyectos: [
      // {
      //   nombre:'Encuesta DOM 2021',
      //   url: 'project_1',
      //   dashboards: [
      //     {
      //       nombre: 'Dashboard 1',
      //       url: 'dashboard_1'
      //     },
      //     {
      //       nombre: 'Dashboard 2',
      //       url: 'dashboard_2'
      //     }
      //   ]
      // }
    ],
    new_proyectos:false
  }),

  created(){
    this.loading = true;
    this.get_proyectos_user(this.user_id);
  },
  computed:{
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    
    user_projects(){
      return this.proyectos;
    },
  },

  // watch:{
  //   updateProjects(e){
  //     console.log('ola')
  //   }
  // },
  methods: {

    // get_proyectos_user(user_id){
    //   let url = API_URL+'file_management/project/?owner_id='+user_id;
    //   axios.get(url)
    //   .then(response => {
    //     if(response.data.count > 0){
    //       console.log('hasProjects')
    //       this.proyectos = response.data.results;
    //     }
    //     else{
    //       console.log('sin proyectos')
    //       this.proyectos = [];
    //     }
    //   })
    //   .catch( err => {
    //     console.log(err)
    //   })

    // },

    updateProjects(new_proyectos){
      if (new_proyectos === true){
        console.log('actualizar')
        this.get_proyectos_user(this.user_id);
      }
      else{
        console.log('as')
      }
    },

    async get_proyectos_user(user_id){
      const response = await axios({
        url: API_URL+'file_management/project/?owner_id='+user_id,
        method: 'GET'
      });

      if(response.data.count > 0){
        // console.log('hasProjects')
        this.sleep(1000).then(() => {
          this.proyectos = response.data.results;
          this.loading = false;
        });
        // let dashboards = response.data.results[0].file_json.data_libros[0].dashboards
      }
      else{
        console.log('sin proyectos')
        this.loading = false;
        this.proyectos = [];
      }

      

    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },


    logout(){
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/index')
        this.sleep(4000).then(() =>{
          this.$router.push('/login')
        });
      })
    }
  }
}
</script>
